
import { FactcheckSuggestion } from "@/types";
import { Component, Vue } from "vue-property-decorator";
import * as FactcheckRequestServices from "@/services/FactCheckRequestServices";

@Component({
    components: {
        FlowFunnel: () => import("@/components/charts/requests/Funnel.vue"),
    }
})
export default class RequestsStats extends Vue {
    requests: FactcheckSuggestion[] = [];

    async getRequests() {
        this.requests = await FactcheckRequestServices.GetRequests();
    }

    percentageOfRequestsWithFactcheck = null as number | null;
    requestsWithFactcheck = 0;

    async mounted() {
        await this.getRequests();
        const statData = await FactcheckRequestServices.GetPercentageOfRequestWithFactCheck();
        this.percentageOfRequestsWithFactcheck = statData.percentage
        this.requestsWithFactcheck = statData.countWithFactcheck
    }

    get requestSourcesPerSource() {
        return this.requests.reduce((acc, request) => {
            if (!acc[request.source]) {
                acc[request.source] = 0;
            }
            acc[request.source]++;
            return acc;
        }, {} as { [key: string]: number });
    }
}
