import { FactcheckSuggestion } from "@/types"

import api from '@/plugins/axios';

export const GetRequests = async (): Promise<FactcheckSuggestion[]> => {

  const { data } = await api.get<FactcheckSuggestion[]>('/factcheck-suggestions')

  return data.map(e => {
    return {
      ...e,
    } as FactcheckSuggestion
  })
}

export const CreateRequest = async (data: Partial<FactcheckSuggestion>): Promise<void> => {
  await api.post('/factcheck-suggestions', data)

  return;
}


export const CreateRequestFromTweet = async (url: string) => {
  await api.post('/factcheck-suggestions/from-tweet', { url })

  return;

}

export const DeleteRequest = async (factCheckRequestId: string): Promise<void> => {
  await api.delete(`/factcheck-suggestions/${factCheckRequestId}`)

  return;
}

export const UpdateRequest = async (factCheckRequestId: string, data: Partial<FactcheckSuggestion>): Promise<void> => {
  await api.patch(`/factcheck-suggestions/${factCheckRequestId}`, data)

  return;
}

export const SendResponse = async (factCheckRequestId: string, text: string, applySignature: boolean): Promise<void> => {
  await api.post(`/factcheck-suggestions/${factCheckRequestId}/reply`, {
    text,
    applySignature,
  })

  return;
}

export const GetPercentageOfRequestWithFactCheck = async () => {
  const { data } = await api.get<{ percentage: number, countTotal: number, countWithFactcheck: number }>('/factcheck-suggestions/percentage-with-factcheck')

  return data;
}